<template>
    <div class="flex h-screen items-center justify-center">
        <div class="w-full max-w-xs px-2 sm:px-0">
            <router-link to="/"><img alt="Boothclub" src="../assets/images/Boothclub-logo.png"></router-link>
            <h1 class="text-50px font-bold tracking-4px mt-8 mb-12 uppercase">Forgot Password</h1>
            <form autocomplete="off" class="font-work-sans" v-on:submit.prevent="forgotPassword" method="post">
                <div class="relative mb-8" v-if="!code_sent">
                    <label for="email" class="text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon icon="envelope" class="text-xl pr-1 align-top"></font-awesome-icon> Email address</label>
                    <input type="email" name="email" id="email" class="bg-transparent w-full outline-none border-b border-gray-custom pb-2 font-work-sans-medium  tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" required v-model="email">
                </div>
                <div class="text-sm mb-8 tracking-wider" v-if="!code_sent">
                    <p>Please enter your email address above and click send button to get your code to change password.</p>
                </div>
                <div class="mb-8" v-if="!code_sent">
                    <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise" type="submit">SEND CODE</button>
                </div>

                <div class="relative mb-8" v-if="code_sent">
                    <label for="code" class="text-sm tracking-wider text-gray-custom absolute block top-0">Code</label>
                    <input type="text" name="code" id="code" class="bg-transparent w-full outline-none border-b border-gray-custom pb-2 font-work-sans-medium  tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" required v-model="code">
                </div>
                <div class="relative mb-8" v-if="code_sent">
                    <label for="password" class="text-sm tracking-wider text-gray-custom absolute block top-0">New Password</label>
                    <input type="password" name="password" id="password" class="bg-transparent w-full outline-none border-b border-gray-custom pb-2 font-work-sans-medium  tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" required v-model="password">
                </div>
                <div class="text-sm mb-8 tracking-wider" v-if="code_sent">
                    <p>Please enter code you received with a new password.</p>
                </div>
                <div class="mb-8" v-if="code_sent">
                    <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise" type="submit">CHANGE PASSWORD</button>
                </div>

                <div class="text-sm mb-8 tracking-wider text-center">
                    <router-link :to="{ name: 'signin' }" class="text-3xl font-bold tracking-wider uppercase">Sign In</router-link>
                </div>
                <div v-show="error" class="text-red-600 mt-8 text-sm text-center break-words" >{{ error }}</div>
            </form>
        </div>
        <modal :showing="showModal" transition="scale" :close-btn="false" @close="showModal = false" class="text-center">
            <div class="text-white text-2xl sm:text-3xl uppercase leading-tight font-bold tracking-wide">Your password changed.<br>Please Signin with new password.</div>
            <br>
            <router-link to="/signin" class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-1/2 border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise block mx-auto">SIGN IN</router-link>
        </modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal.vue';
    import { Auth } from 'aws-amplify';

    export default {
        name: 'ForgotPassword',
        components: {
            Modal
        },
        data: function() {
            return {
                email: null,
                code: null,
                password: null,
                error: false,
                showModal: false,
                code_sent: false,
            }
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-30px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                }
            },
            forgotPassword: function() {

                this.error = false;
                const app = this;

                if( this.code_sent === true ) {

                    (async function() {
                        try{

                            await Auth.forgotPasswordSubmit(app.email, app.code, app.password);
                            app.showModal = true;

                        } catch(err) {

                            app.error = err.message;

                        }
                    })();

                } else {

                    (async function() {

                        try{

                            await Auth.forgotPassword(app.email);
                            app.code_sent = true;

                        } catch(err) {

                            if(err.code === 'UserNotFoundException') {
                                app.error = 'User not found';
                            } else {
                                app.error = err.message;
                            }

                        }
                    })();

                }
            }
        }
    }
</script>

<style scoped>
    label {
        transition: top 0.2s ease-out;
    }
</style>